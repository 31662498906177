.layout-topbar {
    position: fixed;
    height: 100%;
    z-index: 997;
    left: 0;
    top: 0;
    width: 205px;
    padding-top: 20px;
    // padding: 0 2rem;
    background-color: var(--surface-card);
    transition: left $transitionDuration;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
    // overflow-y: auto;
    // overflow-x: hidden;

    .layout-topbar-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--surface-900);
        font-size: 1.5rem;
        font-weight: 500;
        // width: 300px;
        //border-radius: 12px;
        height: 61px;

        // img {
        //     height: 2.5rem;
        //     margin-right: .5rem;
        // }

        // &:focus {
        //     @include focused();
        // }
    }

    .layout-topbar-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        color: var(--text-color-secondary);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        cursor: pointer;
        transition: background-color $transitionDuration;

        &:hover {
            color: var(--text-color);
            background-color: var(--surface-hover);
        }

        &:focus {
            @include focused();
        }

        i {
            font-size: 1.5rem;
        }

        // span {
        //     font-size: 1rem;
        //     display: none;
        // }
    }

    .layout-menu-block {
        margin-top: 15px;
        padding-right: .5rem;
    }
    
    .layout-topbar-menu-button {
        display: none;

        i {
            font-size: 1.25rem;
        }
    }

    .layout-topbar-menu {
        margin: 0 0 0 auto;
        padding: 0;
        list-style: none;
        display: flex;

        .layout-topbar-button {
            margin-left: 1rem;
        }
    }
}

.layout-leftbar {
    position: fixed;
    height: 60px;
    z-index: 996;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px;
    background-color: var(--surface-card);
    transition: left $transitionDuration;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
}

@media (max-width: 771px) {
    .layout-topbar {
        width: calc(100vw - 5rem);
        max-width: 300px;

        .layout-topbar-logo {
            width: auto;
            order: 2;
        }

        .layout-menu-button {
            margin-left: 0;
            order: 1;
        }

        .layout-topbar-menu-button {
            display: inline-flex;
            margin-left: 0;
            order: 3;
        }

        &.layout-topbar-menu-mobile-inactive {
            display: none !important;
        }

        .layout-topbar-menu {
            margin-left: 0;
            position: absolute;
            flex-direction: column;
            background-color: var(--surface-overlay);
            box-shadow: 0px 3px 5px rgba(0,0,0,.02), 0px 0px 2px rgba(0,0,0,.05), 0px 1px 4px rgba(0,0,0,.08);
            border-radius: 12px;
            padding: 1rem;
            right: 2rem;
            top: 5rem;
            min-width: 15rem;
            display: none;
            -webkit-animation: scalein 0.15s linear;
            animation: scalein 0.15s linear;

            .layout-topbar-button {
                margin-left: 0;
                display: flex;
                width: 100%;
                height: auto;
                justify-content: flex-start;
                border-radius: 12px;
                padding: 1rem;

                i {
                    font-size: 1rem;
                    margin-right: .5rem;
                }

                span {
                    font-weight: medium;
                    display: block;
                }
            }
        }
    }
}
