.layout-main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    // padding: 2rem;
    transition: margin-left $transitionDuration;
}


.layout-main-container:has(pages-dash){
    .p-tabview .p-tabview-panels {
        background-color: #f5f5f5 !important;
      }
} 


.layout-main {
    flex: 1 1 auto;
}

