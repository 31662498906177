@media screen and (min-width: 1729px) {
    .layout-main {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 772px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 205px;
                // padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        .layout-leftbar {
            display: none;
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 60px;
            }

            &.layout-static-sidebar-inactive {
                .layout-sidebar {
                    transform: translateX(-100%);
                    left: 0;
                }

                .layout-topbar {
                    width: 60px;
                }

                // .layout-menu li ul ul {
                //     display: none;
                // }

                .layout-menu li a span,
                .layout-menu li a i:last-child {
                    display: none;
                }

                .layout-menu-block {
                    margin-right: 0;
                }

                .layout-main-container {
                    margin-left: 60px;
                    // padding-left: 2rem;
                }
            }
        }

        .layout-mask-mobile.p-component-overlay {
            display: none;
        }

        // .layout-mask {
        //     display: none;
        // }
    }
}

@media (max-width: 771px) {
    .layout-wrapper {
        .layout-main-container {
            padding-top: 60px;
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            z-index: 998;
            // background-color: var(--mask-bg);

            &.layout-mask-enter-from,
            &.layout-mask-leave-to {
                background-color: transparent;
            }
        }

        .layout-mask-mobile {
            z-index: 996;

            &.layout-mask-enter-from,
            &.layout-mask-leave-to {
                background-color: transparent;
            }
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                transform: translateX(0);
            }

            .layout-mask {
                display: block;
            }
        }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }

    .p-sidebar {
        width: 100% !important;
        padding: 0 !important;
    }
}